import { MetaFunction } from "@remix-run/cloudflare";
import { Meta, Links, Outlet, ScrollRestoration, Scripts, LiveReload } from "@remix-run/react";
import Footer from "~/components/layout/footer";
import Header from "~/components/layout/header";
import TransactionNotification from "~/components/transaction-notification";
import RadixProvider from "~/rdt/radixProvider";

import '../styles/app.css';

export function links() {
   return [
      { rel: 'shortcut icon', type: 'image/svg+xml', href: 'https://defiplaza.net/assets/images/logo/icon.svg' },
      { rel: 'mask-icon', sizes: 'any', href: 'https://defiplaza.net/assets/images/logo/icon.svg', color: '#5D0FC0' },
   ];
}

export const meta: MetaFunction = () => {
   return [{ title: 'DefiPlaza on Radix' }];
};

export default function App() {
   return (
     <html lang="en" className="bg-primary-700">
       <head>
         <meta charSet="utf-8" />
         <meta name="viewport" content="width=device-width, initial-scale=1" />
         <meta name="theme-color" content="#3d0283" />
         <Meta />
         <Links />
       </head>
       <body className="oval-gradient min-h-screen bg-primary-700 pb-4 text-white selection:bg-primary-300 selection:text-primary-100">
         <RadixProvider>
           <Header />
           <main className="z-10 mt-5 lg:mt-12">
             <Outlet />
             <TransactionNotification />
             <ScrollRestoration />
           </main>
           <Footer />
         </RadixProvider>

         <Scripts />
       </body>
     </html>
   );
}
